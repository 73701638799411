import { Gondor } from "../Gondor";
import publicIp from 'public-ip';
const ACTIONS = {
  FACEBOOK: "/fb/conversion",
};

export async function pageView() {
      const ipAddress = await publicIp.v4();
      const userAgent = navigator.userAgent;
      sessionStorage.setItem("ipAddress",ipAddress);
      const origin = process.env.VUE_APP_BASE_URL;
      if(!sessionStorage.getItem("eventID")){
        sessionStorage.setItem("eventID", `${origin}/${ipAddress}/${String(new Date().getDate())}/${String(new Date().getMonth())}/${String(new Date().getFullYear())}`)
      }
      window.dataLayer?.push({
        'eventID':  sessionStorage.getItem("eventID")
      });

    return Gondor.http
        .post(ACTIONS.FACEBOOK, {
          pixelId:process.env.VUE_APP_PIXEL_ID,
          token: process.env.VUE_APP_CAPI_TOKEN,
          ipAddress: ipAddress,
          userAgent: userAgent,
          eventName: "PageView",
          eventID : sessionStorage.getItem("eventID"),
          origin: origin,
        }).then((res) => {
          console.log(res);
         });
}
export async function lead() {
      const ipAddress = await publicIp.v4();
      const leadId = sessionStorage.getItem("leadId") || "";
      const userAgent = navigator.userAgent;
      const origin = process.env.VUE_APP_BASE_URL;

         return Gondor.http.post(ACTIONS.FACEBOOK, {
              pixelId:process.env.VUE_APP_PIXEL_ID,
              token: process.env.VUE_APP_CAPI_TOKEN,
              leadid: leadId,
              ipAddress: ipAddress,
              userAgent: userAgent,
              eventName: "Lead",
              eventID : sessionStorage.getItem("eventID"),
              origin: origin,
            });  
}
